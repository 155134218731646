import arrayFrom from 'array-from';
import { loadJsonFileIntoSessionStorage, addClass, removeClass, formatDate } from '../../tools/helpers';

import VideoTeaserLightbox from '../VideoTeaserLightbox';

let isDesktop;

class ContentHubShowStories {

  constructor(options = {}) {
    this.element = options.element;
    this.LARGE_TEASER_CONTAINER = document.getElementById('large-teaser-container');
    this.HTML_STORIES = document.getElementById('grid_html_stories');
    this.GRID_TEMPLATE = 'grid-template';
    this.GRID_TEMPLATE_NEXT = 'grid-template-next';
    this.GRID_STORIES = document.getElementById('grid_stories');
    this.STORY_TEMPLATE = document.getElementById('story-template');
    this.STORY_VIDEO_TEMPLATE = document.getElementById('story-video-template');
    this.SELECT_ALL_ID_DESKTOP = 'select-all-isDesktop';
    this.SELECT_ALL_ID_MOBILE = 'select-all-isMobile';
    this.topics = []; // Initial leeres Topics-Array
    this.currentIndex = 0;
    this.currentPage = 0;
    this.filterChanged = false;
    // this flag is used to forbid both filter observers to trigger the same showStories code
    this.filterCanBeChanged = true;
    this.STORY_JSON = this.GRID_STORIES.getAttribute('data-stories-json');
    this.STORY_JSON_LABEL = this.GRID_STORIES.getAttribute('data-stories-json-label');
    const useCacheWhileFetching = this.GRID_STORIES.getAttribute('data-use-storage');
    this.ALWAYS_FETCH_WITHOUT_CACHE = (useCacheWhileFetching != null && useCacheWhileFetching === 'false');
    this.STORY_PAGE_LABEL = 'storiesPage';
    this.STORY_CLICKED_CARD_TITLE_LABEL = 'storiesClickedCardTitle';
    this.STORY_CLICKED_CARD_LINK_LABEL = 'storiesClickedCardLink';
    this.lang = document.querySelector('html').lang;
    this.langCode = this.lang === 'de' ? 'de-DE' : 'en-US';
  }

  init() {
    this.element.addEventListener('click', () => {
      this.showStories();
    });
    this.setupCategoryButtons();
    this.observeFilterCollectionChanges('.filtercollection');
    this.observeFilterCollectionChanges('.filter-modal__filter-wrapper');

    const sessionStorageStoriesPage = sessionStorage.getItem(this.STORY_PAGE_LABEL);
    if (sessionStorageStoriesPage) {
      if (this.isSelectAllIncludedInTopics()) {
        removeClass(this.HTML_STORIES, 'hidden');
        removeClass(this.element, 'hidden'); // Zeige den "Mehr anzeigen"-Button
      } else {
        addClass(this.HTML_STORIES, 'hidden');
      }

      this.initSessionStoragePage(sessionStorageStoriesPage);
      this.scrollToSavedCard();
    }
  }

  async initSessionStoragePage(sessionStorageStoriesPage) {
    this.currentPage = parseInt(sessionStorageStoriesPage, 10);

    if (this.ALWAYS_FETCH_WITHOUT_CACHE || !sessionStorage.getItem(this.STORY_JSON_LABEL)) {
      await this.loadStoriesFromJson();
    }

    // load json data from session storage
    const dataFromSessionStorage = sessionStorage.getItem(this.STORY_JSON_LABEL);

    if (dataFromSessionStorage != null) {
      for (let i = this.currentPage; i > 0; i -= 1) {
        this.createStoryBoxesAndSetMoreButton(dataFromSessionStorage);
      }
    }
  }

  async showStories() {
    // check if json is not in session storage and then load it from the URL
    if (this.ALWAYS_FETCH_WITHOUT_CACHE || !sessionStorage.getItem(this.STORY_JSON_LABEL)) {
      await this.loadStoriesFromJson();
    }

    // load json data from session storage
    const dataFromSessionStorage = sessionStorage.getItem(this.STORY_JSON_LABEL);
    if (dataFromSessionStorage != null) {
      this.createStoryBoxesAndSetMoreButton(dataFromSessionStorage);

      this.currentPage = this.currentPage + 1;
      this.setStoriesPageInSessionStorage(this.currentPage);
    }

    this.filterCanBeChanged = true;
  }

  createStoryBoxesAndSetMoreButton(dataFromSessionStorage) {
    const hasMoreStories = this.createStoryBoxes(JSON.parse(dataFromSessionStorage), this.currentIndex);

    if (hasMoreStories) {
      this.currentIndex += 6;
      removeClass(this.element, 'hidden'); // Zeige den More Button an, wenn es mehr Stories gibt
    } else {
      addClass(this.element, 'hidden'); // Verberge den More Button, wenn keine weiteren Stories vorhanden sind
    }
  }

  async loadStoriesFromJson() {
    if (this.ALWAYS_FETCH_WITHOUT_CACHE && document.getElementById('stories_from_in_template_json') != null) {
      const storiesJsonInScript = document.getElementById('stories_from_in_template_json');
      sessionStorage.setItem(this.STORY_JSON_LABEL, storiesJsonInScript.innerText);
    } else {
      await loadJsonFileIntoSessionStorage(this.STORY_JSON, this.STORY_JSON_LABEL, this.ALWAYS_FETCH_WITHOUT_CACHE);
    }
  }

  // beobachten ob es bei dem Filterbuttons Änderungen gibt
  observeFilterCollectionChanges(observedSelector) {
    const filterCollection = document.querySelector(observedSelector);
    const observer = new MutationObserver((mutations) => {
      let changedFilterChangeOptionByMe = false;
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class' && this.filterCanBeChanged) {

          this.filterCanBeChanged = false;
          changedFilterChangeOptionByMe = true;
          this.filterChanged = true;
        }
      });

      if (this.filterChanged && (this.filterCanBeChanged || changedFilterChangeOptionByMe)) {
        this.handleFilterChange();
      }
    });

    observer.observe(filterCollection, {
      attributes: true,
      subtree: true, // Beobachtet Änderungen an den Kind-Elementen
    });
  }

  handleFilterChange() {
    this.setupCategoryButtons(); // Aktualisiere das topics-Array
    this.currentIndex = 0;
    this.LARGE_TEASER_CONTAINER.innerHTML = '';
    this.currentPage = 0;
    this.emptyStoriesPageInSessionStorage();

    if (this.isSelectAllIncludedInTopics()) {
      removeClass(this.HTML_STORIES, 'hidden');
      removeClass(this.element, 'hidden'); // Zeige den "Mehr anzeigen"-Button
      this.filterCanBeChanged = true;
    } else {
      addClass(this.HTML_STORIES, 'hidden');
      this.showStories(); // Lade und zeige die gefilterten Stories
    }

    this.filterChanged = false; // Setze filterChanged zurück
  }

  // sammel alle topics aus den Filterbuttons
  // all topics uses the id instead of the label
  setupCategoryButtons() {
    isDesktop = window.innerWidth > 767;
    let categoryButtons;
    if (isDesktop) {
      categoryButtons = document.querySelectorAll('.filtercollection .category-filter');
    } else {
      categoryButtons = document.querySelectorAll('.category-filter.isMobile');
    }

    this.topics = Array.from(categoryButtons)
      .filter(button => button.classList.contains('isActive'))
      .map(button => (this.equalsSelectAllIds(button.getAttribute('data-id')) ? button.getAttribute('data-id') : button.getAttribute('data-label')));
  }

  createStoryBoxes(data, startIndex) {
    const stories = this.filterStories(data);
    const gridTemplate = this.getGridTemplate(startIndex);
    const vimeoOverlayData = data.vimeoOverlay;

    const gridClone = document.importNode(gridTemplate.content, true);
    this.LARGE_TEASER_CONTAINER.appendChild(gridClone);
    const cardPlaces = this.LARGE_TEASER_CONTAINER.lastElementChild.querySelectorAll('.story');

    let displayedStories = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = startIndex; i < startIndex + 6 && i < stories.length; i++) {
      const story = stories[i];
      const currentCardPlace = cardPlaces[displayedStories];
      const bigStoryKeys = data.resolutions['big-story'];
      const smallStoryKeys = data.resolutions['small-story'];

      this.fillCardWithContent(currentCardPlace, story, i, bigStoryKeys, smallStoryKeys, vimeoOverlayData);
      // eslint-disable-next-line no-plusplus
      displayedStories++;
    }

    return stories.length > startIndex + displayedStories;
  }

  filterStories(data) {
    if (this.topics.length > 0 && !this.isSelectAllIncludedInTopics()) {
      return data.stories.filter(story => story.topic.some(topic => this.topics.includes(topic)));
    }

    // only remove the initial html elements when no filter is selected
    const htmlStories = data['html-stories'];
    return data.stories.filter(story => !htmlStories.includes(story.id));
  }

  /**
   This method getGridTemplate returns the template id based upon the state of the grid.
   If the initial stories are shown, the first loaded template should have the large card at the bottom left side.
   If the grid is filtered or if the state is loaded from the session stoarge,
   the first loaded template shozld have the large card at the upper right side.

   Afterwards the next set of cards should have the large story card on the other side respectively.
   */
  getGridTemplate(startIndex) {

    let gridTemplateId = (Math.floor(startIndex / 6) % 2 === 0) ? this.GRID_TEMPLATE_NEXT : this.GRID_TEMPLATE;

    if (this.isSelectAllIncludedInTopics()) {
      gridTemplateId = (Math.floor(startIndex / 6) % 2 === 0) ? this.GRID_TEMPLATE : this.GRID_TEMPLATE_NEXT;
    }

    return document.getElementById(gridTemplateId);
  }

  fillVideoInCardWithContent(storyClone, story, vimeoOverlayData) {
    if (!story.video) {
      return;
    }

    const sectionElement = storyClone.querySelector('.contenthub-videoteaser-lightbox.storycard');
    if (sectionElement) {
      sectionElement.dataset.videoId = story.video.videoId;
    }

    const modalElement = storyClone.querySelector('.modal-layer');
    if (modalElement) {
      modalElement.id = `modal-layer-${story.video.videoId}`;
    }

    const iframeContentScriptElement = storyClone.querySelector('script[data-template-name="consent-given-content"]');
    if (iframeContentScriptElement) {
      iframeContentScriptElement.innerHTML = iframeContentScriptElement.innerHTML.replace('src=""', `src="${story.video.video.srcExternal}"`);
    }

    const iframeOverlayScriptElement = storyClone.querySelector('script[data-template-name="consent-overlay-content"]');
    if (iframeOverlayScriptElement) {
      iframeOverlayScriptElement.dataset.templateName = vimeoOverlayData.templateName;
      iframeOverlayScriptElement.dataset.templateId = vimeoOverlayData.templateId;
      iframeOverlayScriptElement.dataset.processor = vimeoOverlayData.processor;
      iframeOverlayScriptElement.innerHTML = vimeoOverlayData.vimeoScript;
    }
  }

  fillCardWithContent(cardPlace, story, i, bigStoryKeys, smallStoryKeys, vimeoOverlayData) {
    let storyTemplate;
    if (!story.video) {
      storyTemplate = this.STORY_TEMPLATE.content;
    } else {
      storyTemplate = this.STORY_VIDEO_TEMPLATE.content;
    }

    const storyClone = document.importNode(storyTemplate, true);

    if (story.link) {
      storyClone.querySelector('a').href = story.link;
      if (story.link_target_current_window === false) {
        storyClone.querySelector('a').target = '_blank';
      }

      // add click event to save clicked card in session storage
      storyClone.querySelector('a').addEventListener('click', () => {
        sessionStorage.setItem(this.STORY_CLICKED_CARD_TITLE_LABEL, story.headline);
        sessionStorage.setItem(this.STORY_CLICKED_CARD_LINK_LABEL, story.link);
      });
    }

    storyClone.querySelector('.card__headline').textContent = story.headline;
    storyClone.querySelector('.card__subline').textContent = story.subline || (story.publication_date ? formatDate(story.publication_date, this.langCode) : '');

    if (story.text) {
      storyClone.querySelector('.card__text').textContent = story.text;
    }

    const mediaQueries = [
      '(max-width: 320px)', '(max-width: 479px)', '(max-width: 767px)',
      '(max-width: 991px)', '(max-width: 1199px)', '(max-width: 1500px)',
      '(max-width: 10000px)',
    ];

    this.fillVideoInCardWithContent(storyClone, story, vimeoOverlayData);

    const pictureElement = storyClone.querySelector('picture');

    if (story.image) {
      const imageKeys = cardPlace.classList.contains('big') ? bigStoryKeys : smallStoryKeys;
      imageKeys.forEach((key, index) => {
        if (story.image && story.image[key]) {
          const source = document.createElement('source');
          source.srcset = story.image[key];
          source.media = mediaQueries[index % mediaQueries.length];
          pictureElement.appendChild(source);
        }
      });
    }

    const img = document.createElement('img');
    if (story.image) {
      img.src = story.image['614x512'] || '';
    }

    img.loading = 'lazy';
    img.alt = story.image_alt || '';
    pictureElement.appendChild(img);

    const topicCategory = storyClone.querySelector('.topic-category');
    const topicCategoryMobil = storyClone.querySelector('.topic-category.mobil');
    [topicCategory, topicCategoryMobil].forEach((categoryElement) => {
      if (categoryElement) {
        story.topic.forEach((topic) => {
          // eslint-disable-next-line no-param-reassign
          categoryElement.innerHTML += `<span>${topic}</span>`;
        });
      }
    });

    cardPlace.appendChild(storyClone);

    const moduleElements = cardPlace.querySelectorAll('[data-module]');

    // initialize modules; see main/index.js
    arrayFrom(moduleElements).forEach((moduleElement) => {
      const moduleClassName = moduleElement.getAttribute('data-module');

      try {
        if (moduleClassName !== '') {
          const instance = new VideoTeaserLightbox({
            element: moduleElement,
          });
          instance.init();
        }
      } catch (er) {
        console.error(`error initializing module instance with class "${moduleClassName}"`);
        console.error(er);
      }
    });
  }

  setStoriesPageInSessionStorage(storiesPage) {
    sessionStorage.setItem(this.STORY_PAGE_LABEL, storiesPage);
  }

  emptyStoriesPageInSessionStorage() {
    sessionStorage.removeItem(this.STORY_PAGE_LABEL);
  }

  scrollToSavedCard() {
    const storiesClickedCardTitle = sessionStorage.getItem(this.STORY_CLICKED_CARD_TITLE_LABEL);
    const unencodedStoriesClickedCardLink = sessionStorage.getItem(this.STORY_CLICKED_CARD_LINK_LABEL);

    if (unencodedStoriesClickedCardLink != null && storiesClickedCardTitle != null) {
      const storiesClickedCardLink = encodeURI(sessionStorage.getItem(this.STORY_CLICKED_CARD_LINK_LABEL));
      const matchedStoryCards = document.querySelectorAll('.story > .card');

      matchedStoryCards.forEach((storyCard) => {
        const link = storyCard.querySelector('a');
        const headline = storyCard.querySelector('.card__headline');

        if (headline && headline.innerText === storiesClickedCardTitle && link &&
          (link.href === storiesClickedCardLink ||
            link.href === document.location.origin + storiesClickedCardLink)) {
          storyCard.scrollIntoView(false); // scroll bottom end of the element into view
        }
      });
    }
  }

  isSelectAllIncludedInTopics() {
    return this.topics.includes(this.SELECT_ALL_ID_DESKTOP) || this.topics.includes(this.SELECT_ALL_ID_MOBILE);
  }

  equalsSelectAllIds(itemID) {
    return itemID === this.SELECT_ALL_ID_DESKTOP || itemID === this.SELECT_ALL_ID_MOBILE;
  }
}

export default ContentHubShowStories;
